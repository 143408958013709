@font-face {
  font-family: "Silver Charm";
  src: url("../../SilverCharm.otf");
}

@font-face {
  font-family: "Playlist Script";
  src: url("../../PlaylistScript.otf");
}

@import url(https://fonts.googleapis.com/css?family=Lora);

body {
  font-family: "Lora", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  font-size: $default-font-size;
  color: $color-font;
}

.heading-primary {
  backface-visibility: hidden;
}

.heading {
  text-align: center;

  &__secondary {
    display: inline-block;
    font-family: "Playlist Script";
    font-weight: normal;
    font-size: 5rem;
    line-height: 7.6rem;
    letter-spacing: 0.03em;
    margin-bottom: 2.8rem;
    // background-image: linear-gradient(
    //   to right,
    //   $color-primary-light,
    //   $color-primary-dark
    // );
    // color: transparent;

    -webkit-background-clip: text;
    transition: all 0.2s;

    @include respond(tab-port) {
      font-size: 4rem;
    }

    @include respond(phone) {
      font-size: 3.5rem;
    }
  }

  &__tertiary {
    font-family: Lora;
    font-size: 2rem;
    letter-spacing: 0.5em;
    line-height: 2.6rem;
    text-transform: uppercase;
    color: #8e8d8d;
    direction: ltr;
    font-weight: normal;

    p:first-child {
      margin-bottom: 1.5rem
    }

    @include respond(tab-port) {
      font-size: 1.8rem;
    }

    @include respond(phone) {
      font-size: 1.5rem;
    }
  }
}

.paragraph {
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
