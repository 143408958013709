/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 5rem;
  height: 5rem;
  padding: 1rem;
  left: 3rem;
  top: 3rem;
  border-radius: 2.5rem;
  transition: visibility 0.5s, opacity 0.5s linear;
  background-color: #ffc7c8;
  opacity: 0.9;
}

.bm-burger-button:hover {
  opacity: 1;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fff9f9;
  padding: 2.5em 2em 0;
  font-size: 1.6rem;
  width: 100%
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #fff9f9;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  width: 100%;
}

/* Individual item */
.bm-item {
  display: inline-block;
  outline: none;
  width: 100%
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
