@import "abstracts/mixins";
@import "abstracts/variables";

@import "base/base";
@import "base/typography";

@import "burger";
@import "layout/header";

.section-about {
  width: 80%;
  margin: auto;
}

.section-about {
  position: relative;
  height: 700px;
  display: grid;
  // align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  grid-template-columns: 50% 43%;

  &__story {
    margin-top: 2.8rem;
    text-align: justify;
  }

  @include respond(tab-port) {
    grid-template-columns: none;
    align-content: space-evenly;
  }
}

.section-contacts {
  position: relative;
  height: 500px;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 50% 50%;
  background-color: #fff9f9;

  &__info {
    display: grid;
    grid-gap: 4rem;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
  }

  @include respond(tab-port) {
    grid-template-columns: none;
  }
}

.our-portaits {
  background: url(../img/portraits.png);
  width: 100%;
  height: 100%;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.section-counter,
.section-advices {
  height: 600px;
  display: grid;
  grid-template-columns: 50% 43%;
  margin: auto;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  background: #fff9f9;
  padding-left: 10%;
  padding-right: 10%;

  &__headline {
    text-align: center;
  }

  &__headline h2 {
    color: $color-primary;
  }
}

.section-counter {
  @include respond(tab-port) {
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    align-content: space-evenly;
    height: 500px;
  }
}

.section-advices {
  height: 800px;
  grid-template-columns: 40% 55%;
  direction: rtl;

  @include respond(tab-port) {
    grid-template-columns: 100%;
    grid-template-rows: 30% auto;
    height: 650px;
    align-content: space-evenly;
  }
}

.section-program {
  height: 900px;
  display: grid;
  align-items: center;
  grid-template-rows: 25% auto;

  &__program {
    width: 100%;
    height: 100%;
    max-height: 600px;
  }

  @include respond(tab-land) {
    height: 700px;
  }

  @include respond(tab-port) {
    height: 600px;
  }

  @include respond(phone-big) {
    height: 500px;
  }

  @include respond(phone) {
    height: 380px;
  }
}

.section-form {
  &__container {
    margin-top: 5.6rem;
  }
}

.formItem:first-child {
  margin-top: 0;
}

input,
textarea {
  border: none;
  outline: none;
  font-family: "Lora", "sans";
  font-size: 1.6rem;
}

textarea {
  padding-left: 1rem;
  padding-top: 1rem;
}

.form input[type="checkbox"],
.form input[type="radio"] {
  margin-right: 1rem;
}

.formItem {
  margin-top: 2rem;

  &__label {
    margin-bottom: 1rem;

    display: block;
    font-weight: 600;
  }
}

.additionalPersons-form .formItem:first-of-type {
  margin-top: 0;
}

.formItem__textInput {
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  height: 3rem;
}

.formItem__textArea {
  width: 100%;
  height: 80px;
}

.section-important__card-holder {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 2rem;
  justify-items: center;
  justify-content: space-evenly;
  margin-top: 5.6rem;

  @include respond(tab-port) {
    grid-template-columns: none;
    grid-row-gap: 4rem;
  }

  @include respond(big-desktop) {
    grid-template-columns: auto auto auto;
  }
}

button.thumbnail-button {
  border: 4px solid transparent;
}
