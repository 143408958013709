.header {
  height: 100vh;

  padding-top: 2rem;
  background-image: url(../../img/stodola.png);
  background-size: cover;
  background-position: center;
  position: relative;

  &__text-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  @include respond(tab-land) {
    height: 100vh;
  }

  @include respond(big-desktop) {
    height: 90vh;
  }
}

.top-menu {
  height: 10vh;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  justify-content: space-evenly;
  a {
    color: $color-black;
    color: #8e8d8d;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    line-height: 5rem;
  }

  @include respond(tab-port) {
    visibility: hidden;
    height: 0;
    width: 0;
  }
}
